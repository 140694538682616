.ms-auto {
    margin-left: auto !important;
}
.ms-2 {
    margin-left: 0.5rem !important;
}
.ms-3 {
    margin-left: 0.75rem !important;
}
.ms-4 {
    margin-left: 1rem !important;
}
.ms-5 {
    margin-left: 1.25rem !important;
}
.ms-6 {
    margin-left: 1.5rem !important;
}
.ms-7 {
    margin-left: 1.75rem !important;
}

.ps-4 {
    padding-left: 1rem !important;
}
.ps-5 {
    padding-left: 1.25rem !important;
}
.ps-14 {
    padding-left: 3.5rem !important;
}


.me-2 {
    margin-right: 0.5rem !important;
}
.me-3 {
    margin-right: 0.75rem !important;
}
.me-5 {
    margin-right: 1.25rem !important;
}

.pe-2 {
    padding-right: 0.5rem !important;
}
.pe-5 {
    padding-right: 1.25rem !important;
}

.start-100 {
    left: 100% !important;
}
.not_span {
    position: relative;
    bottom: 9px;
    left: -10px;
    font-weight: 700;
}
.modal-header .btn-close {
margin: 0;
padding: 0;    
}

@media (min-width: 600px)
{
  
  .branch_side_border{
    border-left: 1px dashed #B5B5C3; 
    border-left: 0; 
  }
  
.me-15 {
  margin-left: 5.25rem !important;
}
}

.text-start {
    text-align: left !important;
}

a{
    text-align: start;
}
.dropdown-item {
    text-align:  end;
}

.res-height { 
    /* Set height to window height minus navbar height */
    height: calc(100vh - 70px);
    max-height: calc(100vh - 70px); 
}