.ms-auto {
    margin-right: auto !important;
}
.ms-2 {
    margin-right: 0.5rem !important;
}
.ms-3 {
    margin-right: 0.75rem !important;
}
.ms-4 {
    margin-right: 1rem !important;
}
.ms-5 {
    margin-right: 1.25rem !important;
}
.ms-6 {
    margin-right: 1.5rem !important;
}
.ms-7 {
    margin-right: 1.75rem !important;
}
.css-1h9z7r5-MuiButtonBase-root-MuiTab-root , .MuiButtonBase-root , .MuiTab-root , .MuiTab-textColorPrimary {
    font-family: system-ui  !important;
}
.me-lg-10{
    margin-left: 2.5rem !important;
}
.me-10{
    margin-left: 2.5rem !important;
}
.ps-4 {
    padding-right: 1rem !important;
}
.ps-5 {
    padding-right: 1.25rem !important;
}
.ps-14 {
    padding-right: 3.5rem !important;
}


.me-2 {
    margin-left: 0.5rem !important;
}
.me-3 {
    margin-left: 0.75rem !important;
}
.me-5 {
    margin-left: 1.25rem !important;
}

.pe-2 {
    padding-left: 0.5rem !important;
}
.pe-5 {
    padding-left: 1.25rem !important;
}

.start-100 {
    right: 100% !important;
}
.not_span {
    position: relative;
    bottom: 9px;
    right: -10px;
    font-weight: 700;
}
.modal-header .btn-close {
margin: 0;
padding: 0;    
}

@media (min-width: 600px)
{
  
  .branch_side_border{
    border-right: 1px dashed #B5B5C3; 
    border-left: 0; 
  }
  
.me-15 {
  margin-left: 5.25rem !important;
}
}

.text-start {
    text-align: right !important;
}
body ,.menu-title ,.form-label  ,.css-j204z7-MuiFormControlLabel-root{
    font-size: 15px;
    font-family: system-ui;
}
a{
    text-align: end;
}
.dropdown-item {
    text-align: start;
}
